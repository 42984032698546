.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.checkbox-group {
  display: flex;
  flex-direction: column; /* This will align the checkboxes vertically */
  gap: 0px; /* Optional: Adds spacing between checkboxes */
}

.bottom-sheet {
  position: absolute;
  bottom: -80px; /* Adjust based on the size of the bottom sheet */
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
  z-index: 1;
  transition: opacity 0.3s ease;
}

.category-item {
  font-size: 14px;
  padding: 5px 0;
}
.card-industry{

}

.card-text {
  opacity: 0; /* Start with hidden content */
  max-height: 0; /* Initially, the content is hidden */
  overflow: hidden; /* Hide the overflow content */
  transition: opacity 0.5s ease, max-height 0.5s ease; /* Smooth transition */
}

.card-text.show {
  opacity: 1; /* Show content on hover */
  max-height: 1000px; /* Set max-height to allow the content to show */
}

.card-text.hide {
  opacity: 0; /* Hide content when not hovered */
  max-height: 0; /* Set max-height to 0 to hide content */
}

