body {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font to the body */
}

.form-field {
    margin: 10px 0 10px 0;
    padding: 15px;
    font-size: 16px;
    border: 0;
    font-family: "Roboto", sans-serif;
}


input {
    min-height: 48px;
    border-radius:10px !important;
    border: 0.5px solid black;
}

/* Button styles */
.next-button {
    color: black !important;
    min-width: 200px !important;
    border: 1px solid black !important;
    padding: 20px 24px;
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.prev-button{
    color: black !important;
    min-width: 200px !important;
    border: 1px solid black !important;
    padding: 20px 24px;
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.form-group{
    margin-bottom: 30px !important;
}
.form-group label{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}

    /* Add margin for icon */
.next-button .icon {
    margin-left: 8px; /* Space between text and icon */
}
.header{
    margin-bottom: 30px;
}
.header h2{
    font-size: 24px;
    font-weight:600;
}

.dropzone {
    border: 2px dashed #cccccc;
    padding: 50px;
    cursor: pointer;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
}
.img-thumbnail{
    max-height: 400px;
    max-width: 300px;
}
.card-title{
    font-weight: 600;
}

.selected {
    background-color: #D31F2B;
    box-shadow: 0px 8px 16px rgba(217, 83, 79, 0.4);
    color: white;
}
.cursor-pointer {
    cursor: pointer;
}
.slide-in {
    animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
}

.fade-in {
    animation: fade-in 0.5s forwards;
}

.fade-out {
    animation: fade-out 0.5s forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 9999;
    width: auto;
    max-height: 300px; /* Limit the dropdown height */
    overflow-y: auto; /* Enable scrolling if there are too many suggestions */
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 0;
    box-sizing: border-box;
}

.suggestion-item {
    cursor: pointer;
    width: 100%;
}
.suggestion-item:not(:last-child) {
    border-bottom: 1px solid #ccc; /* Add line under each item except the last one */
}
